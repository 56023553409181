import { Widgets } from "modules/Employee/Widgets";
import { Bilding } from "components/Bilding";
import { PostMVP } from "components/PostMVP";
import { SearchAdvanced } from "modules/Employee/SearchAdvanced";
import { PersonalInformation } from "modules/Employee/Profiles/Collaborator/PersonalInformation";
import { ContactDetails } from "modules/Employee/Profiles/Collaborator/ContactDetails";
import { IdentitiesDocuments } from "modules/Employee/Profiles/Collaborator/IdentitiesDocuments";
import { AcademyHistory } from "modules/Employee/Profiles/Competencies/AcademicHistory";
import { SkillsSummary } from "modules/Employee/Profiles/Competencies/SkillsSummary";
import { CurrentEmployment } from "modules/Employee/Profiles/EmploymentInformation/CurrentEmployment";
import { EmploymentsSummary } from "modules/Employee/Profiles/EmploymentInformation/EmploymentsSummary";
import { RecordHistory } from "modules/Employee/Profiles/RecordHistory";
import { Assets } from "modules/Employee/Profiles/Assets";
import { Documents } from "modules/Employee/Profiles/Documents";
import { Compensation } from "modules/Employee/Profiles/Compensation";
import { CerezaNotAccess } from "utils/CerezaNotAccess";
import { KeyIndicators } from "modules/Employee/Home/KeyIndicators";
import { TimeClock } from "modules/Employee/Profiles/TimeClock";
import { WorkTeam } from "modules/Employee/Home/WorkTeam";
import { Petitions } from "modules/Employee/Requests/Petitions";
import { StaffRequest } from "modules/Recruitment/StaffRequests/StaffRequest";
import { Signature } from "modules/Requests/Signature";
import { RequestsHistory } from "modules/Requests/RequestsHistory";
import { Directory } from "modules/Employee/Home/Directory";
import { PresentationLetter } from "modules/Employee/Home";
import { NotificationsCenter } from "modules/Employee/NotificationsCenter";
import { AttendanceReports } from "modules/Employee/ShiftAttendance/AttendanceReports";

export const HOME = [
  {
    name: "IndicadoresClave",
    route: "/home/indicators",
    component: <KeyIndicators />,
  },
  { name: "Directorio", route: "/home/directorio", component: <Directory /> },
  { name: "Documentos", route: "/home/documentos", component: <Bilding /> },
  { name: "WorkTeam", route: "/home/work-team", component: <WorkTeam /> },
];

export const REQUESTS = [
  {
    name: "Requests",
    route: "/requests",
    component: <Petitions application="employee" />,
  },
];

export const ACCOUNT = [
  { name: "Ajustes", route: "/account/configurations", component: <Bilding /> },
  {
    name: "GestiónDeUsuarios",
    route: "/account/management",
    component: <Bilding />,
  },
  {
    name: "InformaciónDeEmpleado",
    route: "/account/employee",
    component: <Bilding />,
  },
  { name: "Cuentas", route: "/account/accounts", component: <Bilding /> },
  {
    name: "Notificaciones",
    route: "/account/notifications",
    component: <Bilding />,
  },
  {
    name: "PlanesYFacturación",
    route: "/account/plans-billing",
    component: <Bilding />,
  },
  {
    name: "HorariosLaborales",
    route: "/account/working-hours",
    component: <Bilding />,
  },
];

export const PROFILE = [
  {
    name: "InformacionGeneral",
    route: "/profile/collaborator/personal-information",
    component: <PersonalInformation />,
  },
  { name: "Estadísticas", route: "/profile/stats", component: <Bilding /> },
  { name: "Referencias", route: "/profile/references", component: <Bilding /> },
  {
    name: "DesarrolloProfesional",
    route: "/profile/desarrolloProfesional",
    component: <PostMVP />,
  },
  {
    name: "RendimientoMétricas",
    route: "/profile/metricsPerformance",
    component: <Bilding />,
  },
  {
    name: "Ajustes",
    route: "/profile/profile-settings",
    component: <Bilding />,
  },
];

export const SHIFT_ATTENDANCE = [
  {
    name: "AsistenciaAusencia",
    route: "/shift-attendance/attendance-absence",
    component: <AttendanceReports />,
  },
  {
    name: "VacacionesDeTiempoLibre",
    route: "/shift-attendance/time-off-holidays",
    component: <PostMVP />,
  },
  {
    name: "HorarioDeTurnoDeTrabajoDeCereza",
    route: "/shift-attendance/cereza-work-shift-schedule",
    component: <Bilding />,
  },
];

export const LERNING_CENTER = [
  {
    name: "AcademiaCereza",
    route: "/learning-center/cereza-academy",
    component: <Bilding />,
  },
  {
    name: "LearningHub",
    route: "/learning-center/learning-hub",
    component: <Bilding />,
  },
  {
    name: "EvaluaciónDeCompetencias",
    route: "/learning-center/competency-assessment",
    component: <PostMVP />,
  },
  {
    name: "Ajustes",
    route: "/learning-center/marketplace-settings",
    component: <Bilding />,
  },
];

export const PERFORMANCE_CENTER = [
  {
    name: "MisIndicadores",
    route: "/performance-center/key-indicators",
    component: <Bilding />,
  },
  {
    name: "Evaluaciones",
    route: "/performance-center/evaluaciones",
    component: <PostMVP />,
  },
];

export const RESOURCES = [
  { name: "Cronograma", route: "/resources/schedule", component: <Bilding /> },
  { name: "Visitantes", route: "/resources/visitors", component: <Bilding /> },
];

export const MEDICAL = [
  {
    name: "Incapacidades",
    route: "/medical/disabilities",
    component: <Bilding />,
  },
  { name: "Registro", route: "/medical/record", component: <Bilding /> },
  { name: "Historia", route: "/medical/history", component: <PostMVP /> },
  {
    name: "CertificadoMédico",
    route: "/medical/medicalCertificate",
    component: <PostMVP />,
  },
  {
    name: "Referrals",
    route: "/medical/medical-referrals",
    component: <PostMVP />,
  },
  {
    name: "Ajustes",
    route: "/medical/medical-settings",
    component: <PostMVP />,
  },
];

export const TOOLS = [
  { name: "Junta", route: "/tools/board", component: <Bilding /> },
  {
    name: "InsigniaYLogro",
    route: "/tools/badge-achievement",
    component: <Bilding />,
  },
  { name: "Boleto", route: "/tools/tickets", component: <PostMVP /> },
];

export const SPECIAL_ROUTES = [
  {
    name: "SearchAdvanced",
    route: "/search/advanced",
    component: <SearchAdvanced />,
  },
  {
    name: "WidgetPage",
    route: "/WidgetPage",
    component: <Widgets />,
  },
  {
    name: "not",
    route: "/auth/not",
    component: <CerezaNotAccess />,
  },
  {
    name: "StaffRequest",
    route: "/recruitment/staff-requests",
    component: <StaffRequest application="employee" />,
  },
  {
    name: "Signature",
    route: "/requests/signature",
    component: <Signature application="employee" />,
  },
  {
    name: "CreateRequests",
    route: "/requests/history",
    component: <RequestsHistory application="employee" />,
  },
  {
    name: "NotificationsCenter",
    route: "/notifications/center",
    component: <NotificationsCenter />,
  },
  {
    name: "PresentationLetter",
    route: "/presentation-letter",
    component: <PresentationLetter />,
  },
];

const PATH_PROFILE = "/profile";
const PATH_COLLABORATOR = `${PATH_PROFILE}/collaborator`;
const PATH_COMPETENCIES = `${PATH_PROFILE}/competencies`;
const PATH_EMPLOYMENT = `${PATH_PROFILE}/employment-information`;

export const PROFILE_MODULE = {
  Collaborator: {
    name: "Collaborator",
    sections: [
      {
        name: "PersonalInformation",
        route: `${PATH_COLLABORATOR}/personal-information`,
        component: <PersonalInformation />,
      },
      {
        name: "ContactDetails",
        route: `${PATH_COLLABORATOR}/contact-details`,
        component: <ContactDetails />,
      },
      {
        name: "IdentitiesDocuments",
        route: `${PATH_COLLABORATOR}/identities-documents`,
        component: <IdentitiesDocuments />,
      },
    ],
  },
  Competencies: {
    name: "Competencies",
    sections: [
      {
        name: "AcademyHistory",
        route: `${PATH_COMPETENCIES}/academy-history`,
        component: <AcademyHistory />,
      },
      {
        name: "SkillsSummary",
        route: `${PATH_COMPETENCIES}/skills-summary-tab`,
        component: <SkillsSummary />,
      },
    ],
  },
  EmploymentInformation: {
    name: "EmploymentInformation",
    sections: [
      {
        name: "CurrentEmployment",
        route: `${PATH_EMPLOYMENT}/current-employment`,
        component: <CurrentEmployment />,
      },
      {
        name: "EmploymentSummary",
        route: `${PATH_EMPLOYMENT}/employment-summary`,
        component: <EmploymentsSummary />,
      },
    ],
  },
  Compensation: {
    name: "Compensation",
    route: `${PATH_PROFILE}/compensation`,
    component: <Compensation />,
    sections: [],
  },
  TimeClock: {
    name: "TimeClock",
    route: `${PATH_PROFILE}/time-clocks`,
    component: <TimeClock />,
    sections: [],
  },
  Documents: {
    name: "Documents",
    route: `${PATH_PROFILE}/documents`,
    component: <Documents />,
    sections: [],
  },
  Assets: {
    name: "Assets",
    route: `${PATH_PROFILE}/assets`,
    component: <Assets />,
    sections: [],
  },
  RecordHistory: {
    name: "RecordHistory",
    route: `${PATH_PROFILE}/record-history`,
    component: <RecordHistory />,
    sections: [],
  },
};
