import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setExpandedSearch } from "@redux/slices/general";
import { setOpenDialogSearch } from "@redux/slices/general";
import { generalSelect } from "@redux/slices/general";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";

const dummy_data = {
  employees: {
    "65577f676a66f67ec0180b79":
      "Johnatan Palacios... names: Johnatan... email: johnatan@cereza.io",
    "655786bcf79de9d53a4147ec":
      "Mexia Banderas... email: johnatan+ab@cereza.io",
    "655cc1b2f3970ee33003627f":
      "John Doe... names: John... email: johnatan+jdoe@cereza.io",
    "655cd919e3e37170b4e23047":
      "John Doe... names: John... email: johnatan+johndoe@cereza.io",
    "655e12f8319cededd1997f56": "Jay P... email: johnatan+jayp@cereza.io",
    "65578cc5985917f9e4f3a9be":
      "Tester Home... email: johnatan+mode-tester@cereza.io",
  },
  candidates: {
    "65693c16f025b11cb8d94b7d": "Jay P ... email: johnatan@cereza.io",
    "6569e7ccf025b11cb8d94b7e": "Jay P ... email: johnatan@cereza.io",
  },
};

export const GenerateResultsList = () => {
  return (
    <>
      {Object.entries(dummy_data).map(([key, values]) => (
        <List
          subheader={
            <ListSubheader component="div">
              <Typography variant="overline">{key}</Typography>
            </ListSubheader>
          }
        >
          {Object.entries(values).map(([id, text]) => (
            <ListItemButton>
              <ListItemText primary={text} />
            </ListItemButton>
          ))}
        </List>
      ))}
    </>
  );
};

export const DialogSearch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { openDialogSearch, expandedSearch } = useSelector(generalSelect);

  return (
    <Dialog
      open={openDialogSearch}
      onClose={() => {
        dispatch(setOpenDialogSearch(false));
        dispatch(setExpandedSearch(false));
      }}
      fullWidth={true}
      scroll="paper"
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <SearchIcon
            sx={{
              mr: 1,
              my: 0.5,
            }}
          />
          <TextField
            variant="standard"
            fullWidth={true}
            id="txt-modal-search"
            expand={expandedSearch.toString()}
            aria-expanded={expandedSearch}
            onChange={() => {
              dispatch(setExpandedSearch(event.target.value !== ""));
            }}
          />
        </Box>
      </DialogTitle>
      <Collapse in={expandedSearch} timeout="auto" unmountOnExit>
        <DialogContent>
          <GenerateResultsList />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              navigate("/search/advanced");
              dispatch(setOpenDialogSearch(false));
              dispatch(setExpandedSearch(false));
            }}
          >
            Advanced search
          </Button>
        </DialogActions>
      </Collapse>
    </Dialog>
  );
};
