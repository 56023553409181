import { cerezaClient } from "../adapters";

const SIGNED = "/v1/org/document/signed";
const ORG = "/v1/org";
const SCHEDULED_PERMISSIONS = "/v1/tc/permission";
const JUSTIFICATION = "/v1/tc/permission/justification";
const PERMISSIONS = "/v1/tc/history/permission";
const JUSTIFICATIONS_HISTORY = "/v1/tc/history/permission/justification";

export function getDocumentsToSign(id) {
  return cerezaClient.get(`${SIGNED}/${id}`);
}

export function singDocument(documentId, employeeId, payload, action) {
  return cerezaClient.post(
    `${ORG}/${action}/document/${documentId}/${employeeId}`,
    payload,
  );
}

export function postScheduledPermissions(payload) {
  return cerezaClient.post(`${SCHEDULED_PERMISSIONS}`, payload);
}

export function getScheduledPermissions(id) {
  return cerezaClient.get(`${SCHEDULED_PERMISSIONS}/${id}`);
}

export function putScheduledPermissions(id, payload) {
  return cerezaClient.put(`${SCHEDULED_PERMISSIONS}/${id}`, payload);
}

export function deleteScheduledPermissions(id) {
  return cerezaClient.delete(`${SCHEDULED_PERMISSIONS}/${id}`);
}

export function postJustification(payload) {
  return cerezaClient.post(`${JUSTIFICATION}`, payload);
}

export function getJustification(id) {
  return cerezaClient.get(`${JUSTIFICATION}/${id}`);
}

export function putJustification(id, payload) {
  return cerezaClient.put(`${JUSTIFICATION}/${id}`, payload);
}

export function getHistoryJustifications() {
  return cerezaClient.get(`${JUSTIFICATIONS_HISTORY}`);
}

export function getHistoryPermissions() {
  return cerezaClient.get(`${PERMISSIONS}`);
}

export function getAutorizationFlowHelper(employeeId) {
  return cerezaClient.get(
    `${ORG}/settings/authorization/flow/helper/${employeeId}`,
  );
}
