import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

const dummy_data = {
  employees: {
    "65577f676a66f67ec0180b79":
      "Johnatan Palacios... names: Johnatan... email: johnatan@cereza.io",
    "655786bcf79de9d53a4147ec":
      "Mexia Banderas... email: johnatan+ab@cereza.io",
    "655cc1b2f3970ee33003627f":
      "John Doe... names: John... email: johnatan+jdoe@cereza.io",
    "655cd919e3e37170b4e23047":
      "John Doe... names: John... email: johnatan+johndoe@cereza.io",
    "655e12f8319cededd1997f56": "Jay P... email: johnatan+jayp@cereza.io",
    "65578cc5985917f9e4f3a9be":
      "Tester Home... email: johnatan+mode-tester@cereza.io",
  },
  candidates: {
    "65693c16f025b11cb8d94b7d": "Jay P ... email: johnatan@cereza.io",
    "6569e7ccf025b11cb8d94b7e": "Jay P ... email: johnatan@cereza.io",
  },
};

export const GenerateResultsList = () => {
  return (
    <>
      {Object.entries(dummy_data).map(([key, values]) => (
        <List
          subheader={
            <ListSubheader component="div">
              <Typography variant="overline">{key}</Typography>
            </ListSubheader>
          }
        >
          {Object.entries(values).map(([id, text]) => (
            <ListItemButton>
              <ListItemText primary={text} />
            </ListItemButton>
          ))}
        </List>
      ))}
    </>
  );
};

function SearchAdvancedTabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

SearchAdvancedTabPanel.propTypes = {
  chidren: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function allyProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const SearchAdvanced = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, value) => {
    setValue(value);
  };

  return (
    <>
      <Tabs value={value} onChange={handleChange} variant="fullWidth">
        <Tab label="AllResults" {...allyProps(0)} />
        <Tab label="Employes" {...allyProps(1)} />
        <Tab label="Candidates" {...allyProps(2)} />
        <Tab label="Vacancies" {...allyProps(3)} />
        <Tab label="Assets" {...allyProps(4)} />
      </Tabs>
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
        }}
      >
        <SearchAdvancedTabPanel value={value} index={0}>
          <GenerateResultsList />
        </SearchAdvancedTabPanel>
        <SearchAdvancedTabPanel value={value} index={1}>
          Employes
        </SearchAdvancedTabPanel>
        <SearchAdvancedTabPanel value={value} index={2}>
          Candidates
        </SearchAdvancedTabPanel>
        <SearchAdvancedTabPanel value={value} index={3}>
          Vacancies
        </SearchAdvancedTabPanel>
        <SearchAdvancedTabPanel value={value} index={4}>
          Assets
        </SearchAdvancedTabPanel>
      </Box>
    </>
  );
};
