import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { jobDescriptionSelect } from "@redux/slices/jobDescription";
import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getCurrencies, getDepartments } from "api";
import { Form, Formik } from "formik";
import { FormJobPosition } from "./FormJobPosition";
import { FormJobSkills } from "./FormJobSkills";
import { Toast, UIButton } from "components";
import Swal from "sweetalert2";
import { postJobPositions, putJobPositions } from "api/organizations";
import { useDispatch } from "react-redux";
import { setJobDescription } from "@redux/slices/jobDescription";
import { deleteJobPositions } from "api/organizations";
import { useNavigate } from "react-router-dom";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { useAccess } from "hooks";
import isEqual from "lodash/isEqual";
import { generalSelect, setCurrencies } from "@redux/slices/general";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { FormJobDescription } from "./FormJobDescription";
import { FormJobProfile } from "./FormJobProfile";
import { FormJobLanguages } from "./FormJobLanguages";
import { FormJobEducation } from "./FormJobEducation";
import { setUpdateStaffs } from "@redux/slices/recruitment";
import { JobDescriptionValidations } from "./JobDescriptionValidations";
import { setRequestUpdated } from "@redux/slices/requests";
import { getJobPositions } from "api";

export const JobDescription = ({
  returnList = false,
  setOpen = () => {},
  readOnly = false,
  application = "organization",
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { jobDescription } = useSelector(jobDescriptionSelect);
  const { currencies } = useSelector(generalSelect);

  const [departments, setDepartments] = useState([]);
  const { languages } = useSelector(generalSelect);
  const [tab, setTab] = useState("profile");

  const [jobs, setJobs] = useState([]);
  const [finish, setFinish] = useState(false);

  const { jobPositionSchema } = JobDescriptionValidations();

  const { HasPermissions } = useAccess();
  const path =
    application === "organization"
      ? "/cereza-organization/organization/job-description"
      : "/job-description";
  let { canAdd, canChange, canDelete, canAutoApprove } = HasPermissions(
    path,
    application,
    ["auto_approve"],
  );
  canAdd = readOnly ? false : canAdd;
  canChange = readOnly ? false : canChange;

  useEffect(() => {
    const getData = async () => {
      try {
        if (!currencies || !currencies?.length) {
          const { data: dCurrencies } = await getCurrencies(true);
          dispatch(setCurrencies(dCurrencies));
        }
        const { data: dDepartments } = await getDepartments();

        setDepartments(
          dDepartments.map(({ _id, name }) => {
            return { value: _id, label: name };
          }),
        );

        const { data: dJobs } = await getJobPositions(null);

        const jobs = dJobs
          .map(({ _id, title }) => {
            return { value: _id, label: title };
          })
          .filter((obj) => obj?.label !== jobDescription?.title);

        setJobs(jobs);

        setFinish(true);
      } catch (err) {
        console.error("Error getting data:", err);
      }
    };

    getData();
  }, []);

  const onSubmit = async (values) => {
    let message = "";

    try {
      await jobPositionSchema.validate(values, { abortEarly: false });

      try {
        const { isWithFlow = true, staffRequests, ...finalValues } = values;

        if (values._id) {
          const { data } = await putJobPositions(finalValues);
          dispatch(setJobDescription(data));
          dispatch(
            setRequestUpdated({
              type: "jobPosition",
              action: "update",
              data: data,
            }),
          );
          message = t("employee:JobDescriptionSuccessfullyUpdate");
        } else {
          const { data } = await postJobPositions(finalValues, isWithFlow);
          dispatch(setJobDescription(data));
          dispatch(
            setRequestUpdated({
              type: "jobPosition",
              action: "add",
              data: data,
            }),
          );
          message = t("employee:JobDescriptionSuccessfullyCreated");
        }

        dispatch(setUpdateStaffs(true));
        setOpen(false);

        Toast.fire({ icon: "success", title: message });
      } catch (e) {
        Toast.fire({
          icon: "error",
          title: `${e?.response?.data?.message}`,
        });
        console.log("Error to submit form: ", e);
      }
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: err.errors.join("; "),
      });
    }
  };

  const deleteItem = async (item) => {
    try {
      await deleteJobPositions(item);
      dispatch(
        setRequestUpdated({
          type: "jobPosition",
          action: "delete",
          data: item,
        }),
      );

      navigate("/cereza-organization/organization/jobs-description");

      Toast.fire({
        icon: "success",
        title: t("employee:JobDescriptionSuccessfullyDelete"),
      });

      dispatch(
        setJobDescription({
          title: "",
          workMode: "",
          quantity: 1,
          department: "",
          reportAt: null,
          profile: {
            gender: "",
            maritalStatus: "",
            minAge: "",
            maxAge: "",
            experience: {
              haveExperience: true,
              howManyYearsOfExperience: 1,
            },
            trainingIn: [],
            specializedKnowledge: [],
            languages: [],
            education: [],
            skills: [],
          },
          description: {
            objective: "<p><br></p>",
            functions: "<p><br></p>",
            responsibilities: "<p><br></p>",
            salaryRange: {
              minSalary: "",
              maxSalary: "",
              currency: "",
            },
          },
          externalRelations: "",
          positionType: "",
          publicationLanguage: "",
          status: "REVIEW_REQUIRED",
        }),
      );
    } catch (err) {
      console.log(err);
      Toast.fire({
        icon: "error",
        title: t("employee:JobDescriptionErrorDelete"),
      });
    }
  };

  const changeTab = (event, newTab) => {
    setTab(newTab);
  };

  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h5" mb={2}>
          {t("employee:JobDescription")}
        </Typography>
        {finish ? (
          <Formik
            initialValues={jobDescription}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form id="d" autoComplete="off">
                <FormJobPosition
                  formik={formik}
                  departments={departments}
                  languages={languages}
                  canAdd={canAdd}
                  canChange={canChange}
                  jobs={jobs}
                />
                <TabContext value={tab}>
                  <Box
                    mt={2}
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                    mb={2}
                  >
                    <TabList onChange={changeTab}>
                      <Tab label={t("recruitment:Profile")} value="profile" />
                      <Tab
                        label={t("employee:Description")}
                        value="description"
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="description" sx={{ p: 0 }}>
                    <FormJobDescription
                      formik={formik}
                      canAdd={canAdd}
                      canChange={canChange}
                      currencies={currencies}
                    />
                  </TabPanel>
                  <TabPanel value="profile" sx={{ p: 0 }}>
                    <FormJobProfile
                      formik={formik}
                      canAdd={canAdd}
                      canChange={canChange}
                    />
                    <FormJobSkills
                      formik={formik}
                      canAdd={canAdd}
                      canChange={canChange}
                    />
                    <FormJobLanguages
                      formik={formik}
                      canAdd={canAdd}
                      canChange={canChange}
                    />
                    <FormJobEducation
                      formik={formik}
                      canAdd={canAdd}
                      canChange={canChange}
                    />
                  </TabPanel>
                </TabContext>
                {!readOnly && (
                  <Stack
                    mt={2}
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                  >
                    {returnList ? (
                      <UIButton
                        variant="contained"
                        startIcon={<ChevronLeftIcon />}
                        onClick={() =>
                          navigate(
                            "/cereza-organization/organization/jobs-description",
                          )
                        }
                        disabled={formik.isSubmitting}
                        fullWidth={false}
                      />
                    ) : (
                      ""
                    )}
                    {((canAdd && formik.values?.status === "REVIEW_REQUIRED") ||
                      (canChange &&
                        ["APPROVED", "INACTIVE"].includes(
                          formik.values?.status,
                        ))) && (
                      <>
                        <UIButton
                          type="submit"
                          label={
                            formik?.values?._id
                              ? t("general:Actualizar")
                              : ["PENDING", "REVIEW_REQUIRED"].includes(
                                    formik.values?.status,
                                  )
                                ? t("general:Request")
                                : t("general:Guardar")
                          }
                          loading={formik.isSubmitting}
                          fullWidth={false}
                        />
                        {canAutoApprove &&
                          ["PENDING", "REVIEW_REQUIRED"].includes(
                            formik.values?.status,
                          ) && (
                            <UIButton
                              type="submit"
                              label={t("employee:sin_autorización")}
                              loading={formik.isSubmitting}
                              onClick={() => {
                                formik.setFieldValue("isWithFlow", false);
                              }}
                              fullWidth={false}
                            />
                          )}
                      </>
                    )}
                    {((canAdd && formik.values?.status === "REVIEW_REQUIRED") ||
                      canDelete) &&
                      formik?.values?._id && (
                        <UIButton
                          label={t("general:Eliminar")}
                          onClick={() => {
                            Swal.fire({
                              title: t("general:Are"),
                              text: t("general:Youwon"),
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: t("general:deleteit"),
                            }).then((result) => {
                              deleteItem(formik.values);
                            });
                          }}
                          disabled={formik.isSubmitting}
                          fullWidth={false}
                        />
                      )}
                    {!isEqual(formik?.values, formik?.initialValues) && (
                      <>
                        {canAdd && (
                          <UIButton
                            label={t("general:DiscardChanges")}
                            onClick={() =>
                              formik.setValues(formik.initialValues)
                            }
                            disabled={formik.isSubmitting}
                            fullWidth={false}
                          />
                        )}
                      </>
                    )}
                  </Stack>
                )}
              </Form>
            )}
          </Formik>
        ) : (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <CircularProgress />
          </Stack>
        )}
      </Box>
    </Paper>
  );
};
