import { Field, useField } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export const UIDatePickerFast = (props) => {
  const { name, ...rest } = props;
  const [field] = useField(name);

  return (
    <Field {...field} {...rest}>
      {({ field, form: { touched, errors, setFieldValue } }) => (
        <>
          <DatePicker
            {...field}
            sx={{
              width: "100%",
            }}
            format="YYYY/MM/DD"
            onChange={(date) => {
              setFieldValue(name, date ? date.format("YYYY-MM-DD") : date);
            }}
            value={field.value ? dayjs.tz(field.value) : dayjs.tz()}
            {...rest}
          />
          {touched[field.name] && errors[field.name] && (
            <div>{errors[field.name]}</div>
          )}
        </>
      )}
    </Field>
  );
};
