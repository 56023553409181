import { TextField } from "@mui/material";
import { Field, useField } from "formik";

export const UITextFast = (props) => {
  const { name, ...rest } = props;
  const [field] = useField(name);

  return (
    <Field {...field} {...rest}>
      {({ field, form: { touched, errors } }) => (
        <>
          <TextField
            sx={{
              width: "100%",
            }}
            {...field}
            {...rest}
          />
          {touched[field.name] && errors[field.name] && (
            <div>{errors[field.name]}</div>
          )}
        </>
      )}
    </Field>
  );
};
