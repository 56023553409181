export const calcularDiasPasados = (fechaEntrada) => {
  const fechaCorta = fechaEntrada.split("T")[0];
  const fechaEntradaObj = new Date(fechaCorta);
  const fechaActual = new Date();
  const diferenciaMilisegundos = fechaActual - fechaEntradaObj;
  const diasPasados = Math.floor(
    diferenciaMilisegundos / (1000 * 60 * 60 * 24)
  );
  return diasPasados;
};
